import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="August 2021"
      subnav="release-notes">
      <div id="August2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Another{' '}
          <a className="uni-link uni-link--article" href="/rn-2021-08.gif">
            tiny-but-mighty
          </a>{' '}
          release this month while a lot of our efforts are focused on Beta
          Module components and planning for the future of Uniform.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.10.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.10.0"
            />

            <div className={styles.changesList}>
              <Added>
                <code>duration</code> prop to{' '}
                <Link href="/components/notifications/toast/code?web#duration">
                  Toasts
                </Link>
                .
              </Added>
              <Added>
                <Link href="/components/notifications/toast/code?web#Toast%20Messenger">
                  ToastMessenger
                </Link>{' '}
                component.
              </Added>
              <Improved>
                Accessibility for{' '}
                <Link href="/components/action-list/code">Action List</Link>,{' '}
                <Link href="/components/forms/select/code">Select</Link>
                {', '}
                and{' '}
                <Link href="/components/forms/select/code/#Creatable%20Select">
                  CreatableSelect
                </Link>
                .
              </Improved>
              <Fixed>
                Performance issue caused by <code>keyGenerator</code> function
                within <Link href="/components/action-bar/code">ActionBar</Link>
                , <Link href="/components/action-list/code">ActionList</Link>,{' '}
                <Link href="/components/card/code">Card</Link>,{' '}
                <Link href="/components/data-visualization/legend/code">
                  Legend
                </Link>
                , <Link href="/components/dialogs/alert/code">Alert</Link> and{' '}
                <Link href="/components/dialogs/modal/code">Modal</Link>.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
